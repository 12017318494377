import { Component, inject } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { StripeService } from "ngx-stripe"
import { Stripe } from "stripe"
import { catchError, map, switchMap } from "rxjs/operators"
import { EMPTY, from, of } from "rxjs"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { CheckoutService, CheckoutSessionLineItem, CustomerDoc } from "./checkout.service"
import { doc, Firestore, getDoc } from "@angular/fire/firestore"
import { toObservable } from "@angular/core/rxjs-interop"
import { AsyncPipe } from "@angular/common"
import { UtilEnvironmentService } from "util/environment"

@Component({
  imports: [
    AsyncPipe,
  ],
  standalone: true,
  template: `
    
    <!--login or checkout as quest-->

    @if (customerId$ | async; as customerId) {
      <button
        class="btn btn-success"
        [disabled]="!checkoutLineItems().length"
        (click)="checkout(customerId, checkoutLineItems())"
      >
        Checkout
        <div>
          {{ checkoutLineItems().length.toString() }}
        </div>
      </button>
    }
  `,
  selector: "e2e-checkout",
})
export class CheckoutComponent {
  private cartService = inject(CheckoutService)
  private authDataAccessService = inject(DataAccessFirebaseAuthService)
  private httpClient = inject(HttpClient)
  private stripeService = inject(StripeService)
  private environmentService = inject(UtilEnvironmentService)
  private firestore = inject(Firestore)

  checkoutLineItems = this.cartService.checkoutLineItems
  afUser = this.authDataAccessService.afUser

  /**
   * currently requires user login
   * TODO: add guest user customerId
   *
   * looks like we need to add a CustomerDoc doc to "customers" and populate the afUser variable
   */
  customerId$ = toObservable(this.afUser)
    .pipe(
      switchMap(user => {
        if (user?.uid) {
          return from(getDoc(doc(this.firestore, "customers", user.uid)))
            .pipe(
              map(snapshot => {
                if (snapshot.exists()) {
                  const firestoreCustomer = snapshot.data() as CustomerDoc
                  return firestoreCustomer.stripeId
                }
                return null
              }),
              catchError(() => of(null)),
            )
        }
        return of(null)
      }),
    )

  checkout(customerId: string | null, checkoutLineItems: CheckoutSessionLineItem[]) {
    if (customerId && checkoutLineItems.length) {
      const baseUrl = this.environmentService.getEnvironment().baseUrl
      const sessionParams: Stripe.Checkout.SessionCreateParams = {
        // billing_address_collection: "required",
        automatic_tax: {
          enabled: true,
        },
        custom_fields: [
          {
            key: "specialInstructions",
            label: {
              type: "custom",
              custom: "Special Instructions",
            },
            type: "text",
          },
        ],
        customer: customerId,
        customer_update: {
          address: "auto",
          name: "auto",
          shipping: "auto",
        },
        // customer_creation: "always",
        // customer_email: "lowell@digitalfarrier.com",
        line_items: checkoutLineItems,
        mode: "payment",
        payment_method_types: ["card"],
        phone_number_collection: {
          enabled: true,
        },
        cancel_url: baseUrl + location.pathname,
        success_url: baseUrl + "/",
        shipping_address_collection: {
          allowed_countries: ["US"],
        },
        shipping_options: [{
          shipping_rate: "shr_1Q08dWF292Q2ICNoRL4Evt3w", // test
          // shipping_rate: "shr_1Q06bkF292Q2ICNoSIV8miqR", // live
        }],
        // return_url: environment.hostingUrl + "/checkout",
        // tax_rates: [TAX_RATE_ID],
        // dynamic_tax_rates: [DYNAMIC_TAX_RATE_ID],
        ui_mode: "hosted",
      }
      const httpPostBody = {
        headers: new HttpHeaders({}),
        sessionParams,
      }
      // console.log(httpPostBody)
      this.httpClient
        .post<Stripe.Checkout.Session>(baseUrl + "/api-v1/create-checkout-session", httpPostBody)
        .pipe(
          switchMap((session) => {
            if (session.id) {
              return this.stripeService.redirectToCheckout({ sessionId: session.id })
            }
            return of({ error: { message: "session id is missing from Stripe.Checkout.Session cloudFunction" } })
          }),
          catchError((err) => {
            console.error(err)
            return EMPTY
          }),
        )
        .subscribe((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result?.error) {
            alert(result.error.message)
          }
        })
    }
  }
}
