import { Environment } from "./environment.model"

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA1PnqE81op7eJt7lxsKJbWpl-YzRjFZkY",
  authDomain: "rtduggan-2.firebaseapp.com",
  databaseURL: "https://rtduggan-2.firebaseio.com",
  projectId: "rtduggan-2",
  storageBucket: "rtduggan-2.appspot.com",
  messagingSenderId: "589775568313",
  appId: "1:589775568313:web:e5af2975526e30a771ca68",
  measurementId: "G-H2N3GC9GB3"
}

export const environment: Environment = {
  production: false,
  baseUrl: "https://rtduggan-2.web.app",
  firebaseConfig,
  stripeKey: "pk_test_lX3RdHF3zLPjUPRzzqY2amol",
  imagekit_urlEndpoint: "https://ik.imagekit.io/bdfm8ldko/rtduggan-2",
  imagekit_publicKey: "public_yaAyPDB3oQr7+5D1+Xi1/Ea4u6o=",
}
