import { Route } from "@angular/router"
import { LoginComponent } from "./components/pages/login.component"
import { DocumentationComponent } from "./components/pages/documentation.component"
import { HomeComponent } from "./components/pages/home.component"
import { BrandsComponent } from "./components/brands.component"
import { SupportComponent } from "./components/support.component"
import { UsersComponent } from "./components/pages/users.component"
import { EventsComponent } from "./components/pages/events.component"
import { SchoolComponent } from "./components/pages/school.component"
import { ProductsComponent } from "./components/pages/products.component"

export const appRoutes: Route[] = [
  { path: "", pathMatch: "full", component: HomeComponent },
  { path: "brands", component: BrandsComponent },
  { path: "documentation", component: DocumentationComponent },
  { path: "events", component: EventsComponent },
  { path: "login", component: LoginComponent },
  { path: "products", component: ProductsComponent },
  { path: "products/:param1", component: ProductsComponent },
  { path: "products/:param1/:param2", component: ProductsComponent },
  { path: "products/:param1/:param2/:param3", component: ProductsComponent },
  { path: "products/:param1/:param2/:param3/:param4", component: ProductsComponent },
  { path: "school", component: SchoolComponent },
  { path: "support", component: SupportComponent },
  { path: "users", component: UsersComponent },
  { path: "**", redirectTo: "", pathMatch: "full" }
]
